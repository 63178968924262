<template>
	<div class="forgetpass">
		<div class="forgetpass_wrap">
			<router-link to='/' class='back_home'><<返回首页</router-link>

			<div class="forgetpass_box">
				<section class="forgetpass_card">
					<div class="forgetpass_title">青海省工业小微企业融资服务平台</div>

					<div class="forgetpass_from">
						<div class="forgetpass_from_left">
							<img src="../../assets/image/11.png" class="forgetpass_pic">
						</div>

						<div class="forgetpass_from_rig">
							<div class="forgetpass_from_top">
								<div class="forgetpass_font">
									<div class="forgetpass_from_title active">忘记密码</div>
									<span class='forgetpass_active'></span>
								</div>
							</div>


							<div class="forgetpass_user" v-if='nextPass'>
								<el-form label-width="43px" :model="formLabelAlign" :rules="rules" ref="formLabelAlign">
								  <el-form-item prop='name'>
								  	 <div class="forgetpass_user_line">
								  	 	<img src="../../assets/image/9.png" class="forgetpass_icon" />
								        <el-input v-model="formLabelAlign.name" placeholder='请输入手机号码/邮箱'></el-input>
								  	 </div>
								  </el-form-item>

								  <el-form-item prop='region'>
								  	 <div class="forgetpass_user_line">
								  	 	<img src="../../assets/image/12.png" class="forgetpass_icon" />
								  	 	<el-input v-model="formLabelAlign.region" placeholder='请输入验证码' type="password"  class='forgetpass_inp'></el-input>
								  	 	<div class="forgetpass_user_code" @click='$throttle(sendCode)' v-if='flag'>发送验证码</div>
								  	 	<div class="forgetpass_user_code forgetpass_wait" v-else>{{num}}s 后重新发送</div>
								  	 </div>
								  </el-form-item>

								  <el-form-item prop='regionpass'>
									  	 <div class="forgetpass_user_line">
									  	 	<img src="../../assets/image/12.png" class="forgetpass_icon" />
								  	 	    <el-input v-model="formLabelAlign.regionpass" placeholder='请输入6-16位数字和大小写字母组合' type="password"></el-input>
									  	 </div>
									 </el-form-item>

									 <el-form-item prop='pass'>
									  	<div class="forgetpass_user_line">
									  	 	<img src="../../assets/image/12.png" class="forgetpass_icon" />
									  	 	<el-input v-model="formLabelAlign.pass" placeholder='请再次输入密码' type="password"></el-input>
									  	 </div>
									  </el-form-item>
								</el-form>
							</div>

							<!-- <div class="forgetpass_user" v-else>
								<el-form label-width="43px" :model="formLabelAlign" :rules="rules" ref="formLabelAlign">
									<el-form-item prop='regionpass'>
									  	 <div class="forgetpass_user_line">
									  	 	<img src="../../assets/image/12.png" class="forgetpass_icon" />
								  	 	    <el-input v-model="formLabelAlign.regionpass" placeholder='请输入6-16位数字和大小写字母组合' type="password"></el-input>
									  	 </div>
									 </el-form-item>

									 <el-form-item prop='pass'>
									  	<div class="forgetpass_user_line">
									  	 	<img src="../../assets/image/12.png" class="forgetpass_icon" />
									  	 	<el-input v-model="formLabelAlign.pass" placeholder='请再次输入密码' type="password"></el-input>
									  	 </div>
									  </el-form-item>
								</el-form>
							</div> -->

							<!-- <div class="forgetpass_submit" v-if='nextPass' @click='queryPass'>下一步</div> -->
							<div class="forgetpass_submit" @click='$throttle(queryPass)'>完成</div>

							<div class="forgetpass_line">
							  <div class="forgetpass_line_no">我已注册，直接 <span class="forgetpass_line_resigter" @click='toLogin'>登录>></span></div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			const checkName = (rule, value, callback) => {
		        if (!value) {
		          return callback(new Error('手机号码/邮箱不能为空'))
		        }

		        setTimeout(() => {
		          if(value.toString().length < 11) {
		          	if(!this.$regEmail.test(value)) {
		          		 callback(new Error('手机号码/邮箱格式错误'))
		          	}else {
		          		callback()
		          	}
		          }else {
		          	if(!this.$regPhone.test(value)) {
		          		callback(new Error('手机号码/邮箱格式错误'))
		          	}else {
		          		callback()
		          	}
		          }
		        }, 500)
		    };
		    const checkRegion = (rule, value, callback) => {
		        if (!value) {
		          return callback(new Error('密码不能为空'))
		        }

		        setTimeout(() => {
		        	
		            if (!this.$regPass.test(value)) {
		              callback(new Error('请输入6-16位数字和大小写字母组合'))
		            } else {
		              callback()
		            }
		        }, 500)
		    };
		    const validatePass = (rule, value, callback) => {
				console.log(value, this.formLabelAlign.regionpass, 8)
		        if (value === '') {
		          callback(new Error('请再次输入密码'))
		        } else if (value !== this.formLabelAlign.regionpass) {
		          callback(new Error('两次输入密码不一致!'))
		        } else {
		          callback()
		        }
		    };
			return {
		        formLabelAlign: {
		          name: '',
		          region: '',
		          regionpass: '',
		          pass: ''
		        },
		        rules: {
		            name: [
		              { required: true, validator: checkName, trigger: 'blur'}
		            ],
		            region: [
		              { required: true, message: '验证码不能为空'}
		            ],
		            regionpass: [
		              { min: 6, max: 16, required: true, validator: checkRegion, trigger: 'blur' }
		            ],
		            pass: [
		              { required: true, validator: validatePass, trigger: 'blur'}
		            ]
		        },
		        code: '',
		        flag: true,
		        nextPass: true,
		        num: 60
			}
		},
		methods: {
			sendCode() {
				if(this.formLabelAlign.name == '') {
					this.$message({
						message: '手机号码不能为空',
						type: 'error'
					})
					return false
				}

				if(!this.$regPhone.test(this.formLabelAlign.name)) {
					this.$message({
						message: '手机号码格式错误',
						type: 'error'
					})
					return false
				}

				let data = {phone: this.formLabelAlign.name}

				this.$get('/get_code', data).then(res => {
					console.log(res.data.status,889)
					if(res.data.status == 1) {
						if(this.timer) {
							clearInterval(this.timer)
						}

						this.flag = false
						this.timer = setInterval(() => {
							this.num--

							if(this.num == 0) {
								clearInterval(this.timer)

								this.flag = true
								this.num = 60
							}
						}, 1000)
					}else {
						this.$message({
							message: res.data.msg,
							type: 'error'
						})
					}
				})
			},
			toLogin() {
				this.$router.replace('/login')
			},
			queryPass() {
				let data = {
					mobile: this.formLabelAlign.name,
					code: this.formLabelAlign.region,
					password: this.formLabelAlign.regionpass,
					repassword: this.formLabelAlign.pass,
					type: this.$route.query.kt
				}

				this.$post('forgetPassword', data).then(res => {
					let type = res.data.status == 1 ? 'success' : 'error'

					this.$message({
						message: res.data.msg,
						type,
						duration: 1000
					})

					if(res.data.status == 1) {
						setTimeout(() => {
							this.$router.replace('/login')
						}, 1000)
					}
				})
			}
		}
	}
</script>

<style scoped>
	@import './forgetpass.css';
</style>